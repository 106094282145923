edit-printing-form {
  th {
    text-align: start;
  }
  form {
    width: 100%;
    margin-top: 3%;

    .mat-mdc-form-field {
      width: 100%;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
