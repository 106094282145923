mat-dialog-actions {
  float: right;

  .mat-mdc-raised-button {
    margin-left: 5px;
  }
}

add-brand-dialog,
edit-brand-dialog,
edit-model-dialog,
add-model-dialog {
  mat-dialog-actions {
    .mat-mdc-raised-button {
      width: 80px;
    }
  }
}

add-model-dialog,
edit-model-dialog {
  .mat-mdc-dialog-content {
    height: 52vh;
  }
}
