@use '@angular/material' as mat;
plotter-form {
  form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    font-family: mat.m2-font-family($magic-typography);
    font-size: mat.m2-font-size($magic-typography, body-1);

    .mat-mdc-select {
      margin-bottom: 13px;
    }
    mat-form-field {
      width: 32%;
    }
  }
}
