@use '@angular/material' as mat;
.mat-toolbar {
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);
  font-weight: normal;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field-infix {
  padding-bottom: 6px;
  padding-top: 0px;
}

.mat-datepicker-toggle {
  vertical-align: bottom !important;
}

.mat-mdc-input-element {
  height: 20px;
}
