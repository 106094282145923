@use '@angular/material' as mat;
admin-detail-profile-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);

  .admin-form-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;

    .is-active-toggle {
      margin-top: 22px;
      width: 32%;
    }

    .mat-mdc-form-field {
      width: 32%;
    }
  }

  .admin-form-row-2 {
    margin-bottom: 5px;

    .mat-mdc-form-field {
      width: 99.2%;
    }
  }

  .admin-form-row-3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;

    .mat-mdc-form-field {
      width: 49% !important;
    }
  }

  .admin-form-row-4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .mat-mdc-form-field {
      width: 88%;
    }

    .mat-mdc-select {
      margin-top: 10px !important;
    }
  }
}
