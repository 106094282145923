transfer-users-form {
  form {
    mat-radio-group {
      display: flex;
      flex-direction: column;

      mat-radio-button {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}
