@use '@angular/material' as mat;
.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  line-height: 3.5;
}

.ngx-datatable {
  margin: 10px;
  font-size: mat.m2-font-size($magic-typography, body-1);

  .datatable-body-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    width: 100%;
  }

  .datatable-header-cell-label {
    font-weight: mat.m2-font-weight($magic-typography, body-1);
  }

  .datatable-body-row:hover {
    background-color: lighten(map-get($magic-background, 100), 22%);
  }

  .datatable-row-odd {
    background-color: lighten(map-get($magic-background, 100), 18%);
  }

  .datatable-header-cell {
    height: 30px;
  }

  .datatable-header {
    border-bottom: 1px solid map-get($magic-primary, 400);
  }

  .datatable-body-cell,
  .datatable-header-cell {
    padding-left: 10px;
  }

  .datatable-body-cell-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    a {
      cursor: pointer;
    }
  }

  .datatable-footer .pager .pages {
    a {
      height: 23px;
      min-width: 23px;
      padding: 5px;
      font-size: mat.m2-font-size($magic-typography, body-1) * 0.9;
      line-height: 13px;
      text-align: center;
    }
  }

  .datatable-footer {
    .datatable-pager {
      margin: 0 10px;

      li {
        vertical-align: middle;
        &.disabled a {
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important;
          cursor: default !important;
        }
        &.active a {
          background-color: map-get($magic-primary, 50);
          font-weight: bold;
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0 6px;
        border-radius: 3px;
        margin: 6px 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;

        &:hover {
          color: rgba(0, 0, 0, 0.75);
          background-color: map-get($magic-primary, 50);
        }
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 20px;
        line-height: 20px;
        padding: 0 3px;
      }
    }
  }
}

.custom-next {
  background-color: red !important;
}

.delete-item {
  padding-right: 14px;
}

.item-cell {
  padding-right: 10px;
}

.logo-item {
  padding-left: 20px !important;
}

.pieces-cell {
  text-align: center;
}

.item-cell,
.delete-item {
  button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 5px;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 9px;
    max-height: 30px;
  }
}

.contents {
  padding: 1%;
  background-color: map-get($magic-background, 50);
  border-radius: 3px;
  box-shadow: 0px 2px 2px map-get($magic-background, 200);
}

img {
  vertical-align: middle;
}
