@use '@angular/material' as mat;
model-admin-form {
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);

  form {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;

    .mat-mdc-select {
      margin-top: 13px !important;
    }

    .select-error {
      font-size: 70%;
    }
  }
}
