@use '@angular/material' as mat;
.mdc-text-field--filled {
  background: transparent !important;
}

.payment-document-form-row-4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3%;

  mat-form-field {
    width: 40%;
    margin-right: 5%;
  }
}
.payment-document-form-row-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;

  mat-form-field {
    width: 49%;

    mat-select {
      width: 30%;
    }
  }
}

.payment-document-form-row-1 {
  margin-bottom: 5px;

  mat-form-field {
    width: 90%;
  }
}

.payment-document-form-row-3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  mat-form-field {
    width: 63%;
  }
}
