@use '@angular/material' as mat;
brand-form {
  display: flex;
  justify-content: space-between;

  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 17px;
    font-family: mat.m2-font-family($magic-typography);
    font-size: mat.m2-font-size($magic-typography, body-1);
    width: 50%;
  }

  img {
    width: 150px;
    height: 150px;
  }

  .mat-mdc-raised-button {
    align-self: start;
    width: 100%;
    margin-top: 15px;
  }

  cropper {
    .mat-mdc-slider-horizontal {
      min-width: 159px;
    }
  }

  mc-image-cropper {
    width: 150px;
    height: 150px;
    border: 5px solid #e5e5da;
    display: block;
  }

  mc-image-cropper img {
    min-width: 100%;
    max-width: none;
  }
}
