super-admin-profile-form,
admin-profile-form,
model-admin-profile-form,
admin-profil-tab {
  form {
    width: 100%;
    margin-top: 1%;

    .mat-mdc-form-field,
    .mat-mdc-select {
      width: 49%;
    }

    .mat-mdc-select {
      padding-bottom: 1.296875em;
    }

    mat-form-field {
      margin-left: 5px;
      margin-right: 5px;
    }

    mat-select {
      margin-left: 5px;
      margin-right: 5px;
    }

    .buttons {
      display: inline-flex;
      float: right;
      .mat-mdc-raised-button {
        margin-right: 5px;
      }
    }
  }
}

.contents.form-contents {
  padding-bottom: 55px;
}
