delete-brand-dialog-dumb,
delete-serie-dialog-dumb {
  ul {
    list-style-type: none;
  }

  a.mat-mdc-raised-button {
    width: 150px;
    margin-bottom: 5px;
    background-color: #d6d6d6;
  }
}
