@use '@angular/material' as mat;
admin-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);

  .mdc-text-field--filled {
    background: transparent !important;
  }
  .admin-form-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;

    .is-active-toggle {
      margin-top: 22px;
      width: 45%;
    }

    mat-form-field {
      width: 49%;
    }
  }

  .admin-form-row-2 {
    margin-bottom: 5px;

    mat-form-field {
      width: 100%;
    }
  }

  .admin-form-row-3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;

    mat-form-field {
      width: 49%;
    }
  }

  .admin-form-row-4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    mat-form-field {
      width: 83%;
    }

    mat-select {
      margin-top: 10px !important;
    }
  }
}
