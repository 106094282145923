@use '@angular/material' as mat;
page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: mat.m2-font-size($magic-typography, body-1);
  font-family: mat.m2-font-family($magic-typography);
  width: 45%;
  margin: auto;
  margin-top: 10%;
  padding: 1%;

  .number {
    font-size: 65px;
    color: map-get($magic-primary, 300);
  }
}
