@use '@angular/material' as mat;
change-password-form {
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    font-family: mat.m2-font-family($magic-typography);
    font-size: mat.m2-font-size($magic-typography, body-1);
  }

  .mismatch-error {
    margin-top: -12px;
    font-size: 70%;
  }
}
