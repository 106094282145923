@use '@angular/material' as mat;
user-credit-form,
admin-credit-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);

  .mdc-text-field--filled {
    background: transparent !important;
  }

  .credit-form-row-4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3%;

    mat-form-field {
      width: 40%;
      margin-right: 1%;
    }
  }
}
