.admin-profile-contents {
  padding: 1%;
  background-color: map-get($magic-background, 50);
  border-radius: 3px;
  box-shadow: 0px 2px 2px map-get($magic-background, 200);
  margin-top: 15px;

  admin-detail-profile-form {
    padding-bottom: 40px;
  }

  .buttons {
    margin-top: -40px;
    display: inline-flex;
    float: right;
    .mat-mdc-raised-button {
      margin-right: 5px;
    }
  }
}
