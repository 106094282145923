@use '@angular/material' as mat;
.mat-mdc-select {
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);
  margin-top: 0% !important;
  display: contents !important;
}

.mat-mdc-option {
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);
}

.mat-mdc-select-trigger {
  font-size: mat.m2-font-size($magic-typography, body-1);
}
