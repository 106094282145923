user-details {
  user-details-tabs {
    user-printing-log {
      user-printing-filter {
        display: flex;

        form {
          display: flex;
        }
      }
    }
  }
}
