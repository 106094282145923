.mat-mdc-progress-spinner {
  margin-left: auto;
  margin-right: auto;
}

.datatable-body-cell-label {
  spinner {
    display: inline-block;
    margin-left: 25px;
    .mat-mdc-progress-spinner {
      height: 28px !important;
      width: 25px !important;
    }
  }
}

.mat-mdc-tab-links {
  spinner {
    display: inline-block;
    .mat-mdc-progress-spinner {
      height: 45px !important;
      width: 25px !important;
    }
  }
}

.header-spinner {
  position: absolute;
  top: 90px;
  left: 50%;
  .mat-mdc-progress-spinner {
    height: 35px !important;
    width: 35px !important;
  }
}

.buttons {
  .mat-mdc-progress-spinner {
    margin-right: 10px;
  }
}
