/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import 'themes/palette';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$magic-typography: mat.m2-define-typography-config(
  $font-family: 'Arial',
  $headline-6: mat.m2-define-typography-level(20px, 20px, bold),
  $body-1: mat.m2-define-typography-level(15px, 15px, bold),
);

$magic-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $magic-primary,
      accent: $magic-accent,
      warn: $magic-warn,
    ),
    typography: $magic-typography,
    density: -1,
  )
);

$default-link: map-get($magic-primary, 500);
$default-font: mat.m2-font-family($magic-typography);

$login-form-background: rgba(255, 255, 255, 1);
$main-background: rgba(188, 188, 188, 0.27);

@include mat.all-component-themes($magic-theme);
@include mat.all-component-typographies($magic-typography);

html,
body {
  height: 100%;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;

  .background {
    flex: 1;
  }
}

body {
  font-family: $default-font;
  margin: 0;
  a {
    color: $default-link;
  }
}

@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

@import 'themes/header-menu';
@import 'themes/admin-form';
@import 'themes/forms';
@import 'themes/dialog';
@import 'themes/printing-visualisation-dialog';
@import 'themes/user-details';
@import 'themes/ngx-datatable';
@import 'themes/model-viewer';
@import 'themes/brand-form';
@import 'themes/serie-form';
@import 'themes/model-form';
@import 'themes/footer';
@import 'themes/login-form';
@import 'themes/toolbar';
@import 'themes/layout';
@import 'themes/main';
@import 'themes/date-picker';
@import 'themes/mat-select';
@import 'themes/search-button';
@import 'themes/user-form';
@import 'themes/editable-user-profile';
@import 'themes/admin-profile-tab';
@import 'themes/admin-detail-profile-form';
@import 'themes/change-password-dialog';
@import 'themes/transfer-users-form';
@import 'themes/add-printable-data-dialog';
@import 'themes/edit-printing-dialog';
@import 'themes/edit-model-admin-dialog';
@import 'themes/error-404';
@import 'themes/delete-dialogs';
@import 'themes/spinner';
@import 'themes/banner-form';
@import 'themes/profile-credit';
@import 'themes/credit-form';
@import 'themes/payment-document-form';
@import 'themes/address-form';
@import 'themes/plotter-form';
