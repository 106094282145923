printable-data-add-dumb {
  .printable-data-top {
    display: flex;
    justify-content: space-between;

    form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(97% - 130px);

      .mat-mdc-form-field,
      .mat-mdc-select {
        width: 23%;

        .mat-mdc-progress-spinner {
          width: 20px;
          height: 20px;
        }
      }

      .mat-mdc-select-trigger {
        margin-bottom: 12px;

        .mat-mdc-select-placeholder.mat-floating-placeholder {
          top: -18px !important;
        }
      }
    }

    .mat-mdc-raised-button {
      padding: 0 10px;
      width: 130px;
    }
  }

  .svg-viewer {
    margin-top: 10px;
    min-height: 100px;
    border: 1px dashed;
  }
}
