admin-management-filter,
global-users-filter,
super-admin-printing-filter,
super-admin-credit-log-filter,
super-admin-payment-document-log-filter,
admin-payment-document-log-filter,
admin-credit-log-filter,
admin-users-filter,
admin-printing-filter,
user-printing-filter,
brand-filter,
serie-filter,
model-filter,
printable-data-filter,
plotter-log-filter {
  .mat-toolbar {
    background: map-get($magic-background, 50);
    box-shadow: 0px 2px 2px map-get($magic-background, 200);
  }

  .mat-toolbar {
    display: flex;

    form {
      display: flex;
      align-items: baseline;
      float: left;
      width: 100%;

      tag-input {
        margin-right: 1%;
        margin-left: 1%;
        align-self: center;
      }

      .mdc-text-field--filled {
        background: map-get($magic-background, 50);
      }

      .mat-mdc-form-field-infix {
        padding-bottom: 2px !important;
      }

      .mat-mdc-form-field {
        line-height: 2;
        margin-right: 1%;
        margin-left: 1%;
        align-self: center;
      }

      .admin-form-field {
        min-width: 33%;
      }

      .mat-mdc-mini-fab {
        align-self: center;
        margin-left: 1%;
      }

      .mat-mdc-select {
        margin-right: 1%;
        margin-left: 1%;
        margin-top: 1.5%;
        align-self: center;
      }
    }

    .add-btn {
      float: right;
      text-align: center;
    }

    .mat-toolbar {
      display: none;
    }
  }
}

model-admin-toolbar {
  .mat-toolbar {
    padding: 0 !important;
  }

  .mat-toolbar {
    flex-direction: row-reverse;

    .add-btn {
      float: right;
      text-align: center;
      width: 10%;
    }
  }
}

.mat-toolbar {
  padding: 0 10px !important;
  background-color: map-get($magic-background, 50);
  border-radius: 3px;
  box-shadow: 0px 2px 2px map-get($magic-background, 200);
}
