mp-footer {
  padding-left: 0;
  display: block;
  padding-top: 10px;
  background-color: map-get($magic-primary, 700);

  .footer-copyright {
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 15px;
    color: map-get($magic-primary, 50);
    background-color: map-get($magic-primary, 900);
    font-size: 75%;
  }
}
