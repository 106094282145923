@use '@angular/material' as mat;
address-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  font-family: mat.m2-font-family($magic-typography);
  font-size: mat.m2-font-size($magic-typography, body-1);

  .mdc-text-field--filled {
    background: transparent !important;
  }

  .address-form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;

    mat-form-field {
      width: 49%;
    }
  }
}
