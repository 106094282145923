@use '@angular/material' as mat;
model-form {
  display: flex;
  justify-content: space-between;

  form {
    display: flex;
    flex-direction: column;
    font-family: mat.m2-font-family($magic-typography);
    font-size: mat.m2-font-size($magic-typography, body-1);
    width: 50%;
  }

  .mat-mdc-raised-button {
    align-self: start;
    width: 100%;
    margin-top: 28px;
  }

  .text-area {
    height: 53px;
  }

  cropper {
    mc-image-cropper {
      width: 350px;
      height: 230px;
    }
    .mat-mdc-slider-horizontal {
      min-width: 360px;
    }
  }

  .picture {
    display: flex;
    align-self: end;
    img {
      width: 360px;
      height: 240px;
      border: 5px solid #e5e5da;
    }
  }
}
