header-menu {
  display: flex;
  background-color: map-get($magic-background, 500);
  align-items: center;
  padding: 30px;
  padding-bottom: 0px;

  .logo img {
    width: 48px;
    height: auto;
  }

  nav {
    flex: 1;

    .mat-mdc-tab-link .mdc-tab__text-label {
      color: map-get($magic-primary, 50) !important;
      min-width: auto;
    }
    .mdc-tab-indicator__content--underline {
      border-color: map-get($magic-accent, A400) !important;
    }
  }

  .logout {
    .mat-mdc-button {
      color: map-get($magic-primary, 50);
    }
  }
}
