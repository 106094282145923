@use '@angular/material' as mat;
serie-form {
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    font-family: mat.m2-font-family($magic-typography);
    font-size: mat.m2-font-size($magic-typography, body-1);

    .mat-mdc-select {
      margin-bottom: 13px;
    }
  }
}
