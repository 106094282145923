@use '@angular/material' as mat;
login:before,
request-password-reset:before {
  content: '';
  background: url('/assets/background.png') center center;
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;

  filter: invert(100%);
}

login:after,
request-password-reset:after {
  $color: map-get($magic-primary, 500);

  content: '';
  display: block;
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient($color 1px, transparent 1px), linear-gradient(90deg, $color 1px, transparent 1px),
    linear-gradient(rgba($color, 0.4) 1px, transparent 1px), linear-gradient(90deg, rgba($color, 0.4) 1px, transparent 1px);
  background-size:
    50px 50px,
    50px 50px,
    10px 10px,
    10px 10px;
  background-position:
    -2px -2px,
    -2px -2px,
    -1px -1px,
    -1px -1px;
  opacity: 0.5;
  z-index: 1;
}

login-form,
request-password-reset-form,
reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: mat.m2-font-size($magic-typography, body-1);
  font-family: mat.m2-font-family($magic-typography);
  width: 40%;
  margin: auto;
  position: absolute;
  top: 20%;
  left: 30%;
  right: 30%;
  z-index: 10;

  padding: 1%;
  background-color: $login-form-background;
  box-shadow: 0px 2px 2px map-get($magic-background, 200);
  border-radius: 3px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mdc-text-field--filled {
      background: transparent !important;
    }
    .mat-mdc-raised-button {
      margin-top: 7%;
      width: 100%;
    }
  }

  a {
    align-self: flex-end;
    margin-top: 7%;
    font-size: mat.m2-font-size($magic-typography, body-1) * 0.8;
    cursor: pointer;
  }

  mat-form-field {
    width: 150%;
  }
}

request-password-reset-form,
reset-password-form {
  h3 {
    color: #676565;
  }

  .mdc-text-field--filled {
    background: transparent !important;
  }

  .mat-mdc-raised-button {
    margin-bottom: 20px;
  }

  .mismatch-error {
    margin-top: -12px;
    font-size: 70%;
    align-self: start;
    margin-left: -46px;
  }
}
